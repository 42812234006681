import React from 'react';

import Section from '../section';

const SectionAbout = ({ about }) => {
  return (
    <Section title="About Me">
      <div className="mb-6">
        <p>{"I love exploring new technologies, studying finance & crypto, and learning about cars!"}</p>
        <p>{"I'm passionate about software development, cloud computing, and problem solving."}</p>
      </div>
    </Section>
  );
};

export default SectionAbout;
