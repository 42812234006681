import React from 'react';

const classes = {
  wrapper: 'block wrapper mx-auto px-5 light:bg-gray-900',
  row: 'h-px mt-6 border-gray-300 max-w-screen-xl mx-auto',
  contentWrapper: 'flex-none pt-6 md:pt-1 md:flex-1 md:pl-20',
  text: 'text-sm text-gray-700 light:text-gray-200 mx-1',
  link: 'text-sm text-gray-700 light:text-gray-200 underline',
};

const Footer = () => {
  return (
    <div className={classes.wrapper}>
      <hr className={classes.row}/>
        <div class="md:py-5">
          <div class="flex flex-col items-center justify-between mt-6 md:mt-0 md:flex-row">
            <div class="logo flex items-center">
              <p className={classes.text}> Built with ♡ by Taylan Unal </p>
              <a className={classes.link}
                href="https://github.com/taylanu/taylanu-devfolio">Source on Github</a>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Footer;
